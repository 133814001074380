@import "../../css/App.scss";

.AddFile {
  .header {
    text-align: center;
    margin-top: 2.375rem;

    img {
      width: 6.5625rem;
    }

    h1 {
      font-size: 1.375rem;
      color: $color-blue;
    }
  }

  form {
    width: 100%;
    padding: 1rem 0;

    @include bp(bp-xs) {
      padding: 1rem 0;
    }

    .btn-add {
      background-color: $color-grey-2;
      color: $color-white;
      font-size: 3.125rem;
      padding: 2.5rem;
      height: 6.25rem;
      width: 6.25rem;
      border-radius: 0.25rem;
      text-align: center;
      cursor: pointer;
    }

    .form-control {
      height: 2.5rem;
    }
  }

  .form-file {
    height: 0;
  }

  .custom-file-input {
    z-index: 9;
  }

  .figure {
    overflow: hidden;
    max-width: 100%;
    &-img {
      height: 6.5625rem;
      max-width: 100%;
      object-fit: fill;
      display: block;
      margin: 0 auto;
      border-radius: 0.3125rem;
    }

    &-caption {
      white-space: break-spaces;
      line-break: anywhere;
    }
    &-remove {
      position: absolute;
      top: 0;
      right: 1rem;
      border: 2px solid $color-grey-4;
      border-radius: 50%;
      width: 19px;
      height: 19px;
      color: $color-grey-4;
      font-weight: $font-weight-bold;
      background-color: $color-white;
      cursor: pointer;

      &:before {
        content: "x";
        position: absolute;
        top: -4px;
        left: 3px;
      }
    }
  }
}

.modal {
  &-footer {
    .btn {
      line-height: 2;
      width: 8.5rem;
    }
  }
}
