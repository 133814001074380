@import "../../css/App.scss";

.sidebar {
  padding: 1.875rem 1.25rem;
  border-right: 1px solid $border-color;

  &__links {
    margin-bottom: 4.375rem;
    .item {
      font-family: $font-family-bold;
      font-weight: 700;
      margin: 1.1875rem 0;
      color: $menu-item-unselected;
    }
  }

  &__support {
    .item {
      margin: 0.625rem 0;
      font-family: $font-family-bold;
      color: $menu-item-unselected;
    }

    .email {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  svg {
    margin-right: 0.625rem;
  }
}
