@import "./OverrideBootstrap.scss";
@import "./Breakpoints.scss";
@import "./Variables.module.scss";
@import "./Svg.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: $font-size;
  font-family: $font-family;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: 1.4;
  height: 100vh;
  width: 100%;
  margin: 0;
  color: $color-text;
}

a {
  color: $color-text;
  text-decoration: none;
}

label {
  font-size: 1rem;
  height: 1.2rem;
  color: $color-grey-4;
  line-height: 1.2rem;
  font-weight: $font-weight-bold;
  display: block;
  font-family: $font-family-bold;
}

table,
thead,
tbody,
tr,
td {
  color: $color-grey-4;
}
a {
  &:not([href]):not([class]) {
    color: $color-white;
  }
}

.img {
  &--bg {
    height: auto;
    min-height: 100%;
    background-size: cover;

    &.contain {
      background-size: contain;
    }
  }
}
