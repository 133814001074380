@import "../../css/App.scss";

.SocialMediaFeed {
  .scrollable {
    height: -o-calc(100vh - 118px); /* opera */
    height: -webkit-calc(100vh - 118px); /* google, safari */
    height: -moz-calc(100vh - 118px); /* firefox */
    height: calc(100vh - 118px);
    overflow-y: auto;
  }
}

.FacebookPublish {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
