@import "./Variables.module.scss";

@mixin bp($point) {
  $bp-xs: "(max-width: " + $screen-xs + "px)";
  $bp-sm: "(max-width: " + $screen-sm + "px)";
  $bp-md: "(max-width: " + $screen-md + "px)";
  $bp-lg: "(max-width: " + $screen-lg + "px)";
  $bp-xl: "(max-width: " + $screen-xl + "px)";
  @if $point == bp-xl {
    @media #{$bp-xl} {
      @content;
    }
  }

  @if $point == bp-lg {
    @media #{$bp-lg} {
      @content;
    }
  } @else if $point == bp-md {
    @media #{$bp-md} {
      @content;
    }
  } @else if $point == bp-sm {
    @media #{$bp-sm} {
      @content;
    }
  } @else if $point == bp-xs {
    @media #{$bp-xs} {
      @content;
    }
  }
}
