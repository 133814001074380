@import "../../css/App.scss";

.ChangePassword {
  .logo {
    text-align: center;
    margin-top: 1.4rem;
    img {
      width: 8rem;
    }
  }

  .heading {
    color: $color-blue;
    text-align: center;
    font-weight: $font-weight-bold;
    margin: 2rem 0;
  }

  .form--changePassword {
    padding: 2.5rem 3.125rem;

    @include bp(bp-sm) {
      padding: 0.625rem 1.875rem;
    }

    @include bp(bp-xs) {
      padding: 3.125rem 1.875rem;
    }
  }

  .forgot-password {
    margin: 1.875rem 0;
    text-align: center;
  }
}
