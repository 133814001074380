@import "../css/App.scss";

.top-nav {
  height: 4rem;
  background-color: $color-blue;
  position: relative;
  padding: 0 1.3125rem;

  .logo {
    margin-right: 3.125rem;
    width: 11rem;
    height: 2.5rem;

    &--mobile {
      margin: 0.625rem 1.3rem 0.625rem 0;
      width: 2.5rem;
      height: 2.5rem;
      vertical-align: bottom;
    }
  }

  .hamburger {
    display: inline-block;
    width: 1.2rem;
    height: 1rem;
    margin: 1.5rem 0 0 0.7rem;

    &:hover {
      cursor: pointer;
    }

    @include bp(bp-sm) {
      margin: 1.4rem 0 1.4rem 0.7rem;
    }
  }

  .log-out {
    position: absolute;
    right: 1.5rem;
    top: 0.4rem;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }
}
