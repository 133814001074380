@import "../../../css/App.scss";

.History {
  .navbar {
    height: 4rem;
    border-bottom: 1px solid #d8d8d8;
    background-color: $color-grey-1;

    &__secondary {
      background-color: $color-white;
    }

    .form-label {
      margin-right: 0.625rem;

      @include bp(bp-sm) {
        margin-bottom: 0rem;
        display: inline-block;
      }
    }

    .form-group {
      padding: 0.25rem;
      @include bp(bp-sm) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 0;
        box-sizing: border-box;
      }
    }
    .form-control {
      height: 2rem;
      text-align: center;
      border: 1px solid #d8d8d8;
      height: 2.5rem;
      width: 100%;
    }

    .add-file {
      margin-left: 1.25rem;
      height: 2rem;
      line-height: 0rem;
    }
  }
}

.file-dot {
  border-radius: 50%;
  width: 0.84rem;
  height: 0.84rem;
  display: inline-block;
  margin-right: 0.625rem;

  &.fog {
    background-color: $color-fog;
  }
  &.uco {
    background-color: $color-uco;
  }
  &.mixed {
    background-color: $color-mixed;
  }
}

.infinite-table {
  .body-table {
    height: -o-calc(100vh - 10rem); /* opera */
    height: -webkit-calc(100vh - 10rem); /* google, safari */
    height: -moz-calc(100vh - 10rem); /* firefox */
    height: -webkit-calc(100vh - 10rem);
    overflow: auto;

    .initial-message{
      padding-left: 0.75rem;
    }

    @include bp(bp-sm) {
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      thead tr {
        position: absolute;
        top: -9999px;
        left: -999px;
      }

      tr {
        border-bottom: 1px solid #d8d8d8;
        padding: 1rem;

        &:first-of-type {
          border-top: 1px solid #d8d8d8;
        }

        &:nth-child(odd) {
          background-color: $color-grey-1;
        }
      }

      td {
        border: none;
        position: relative;
        padding: 0.3rem 0 0 50%;
        color: $color-grey-4;

        &:empty {
          height: 27px;
        }

        &:before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 0.375rem;
          left: 0.375rem;
          width: 45%;
          padding-right: 0.625rem;
          white-space: nowrap;
          font-family: $font-family-bold;
          font-weight: 700;
          color: $color-grey-4;
        }

        &:nth-of-type(1) {
          padding-left: 5.5625rem;
          &:before {
            content: "File Name";
          }
        }
        &:nth-of-type(2) {
          padding-left: 5.3125rem;
          &:before {
            content: "Category";
          }
        }
        &:nth-of-type(3) {
          padding-left: 8.5rem;
          &:before {
            content: "Waste Company";
          }
        }
        &:nth-of-type(4) {
          padding-left: 10.75rem;
          &:before {
            content: "Waste Document No.";
          }
        }
        &:nth-of-type(5) {
          padding-left: 8.125rem;
          &:before {
            content: "Waste Quantity";
          }
        }
        &:nth-of-type(6) {
          padding-left: 9.9375rem;
          &:before {
            content: "No. of Attachments";
          }
        }
        &:nth-of-type(7) {
          padding-left: 6.4375rem;
          &:before {
            content: "Date Added";
          }
        }
      }
    }

    @include bp(bp-sm) {
      height: -o-calc(100vh - 11rem); /* opera */
      height: -webkit-calc(100vh - 11rem); /* google, safari */
      height: -moz-calc(100vh - 11rem); /* firefox */
      height: -webkit-calc(100vh - 11rem);
      overflow: auto;
    }
  }
  .header {
    height: 2rem;
  }
}
