@import "./Variables.module.scss";

$icons: ".user, .password, .envelope, .phone, .twitter, .history, .education, .menu";

.svg {
  width: 1rem;
  &.green {
    & #{$icons} {
      fill: $color-green;
    }
  }

  &.grey-5 {
    & #{$icons} {
      fill: $color-grey-5;
    }
  }

  &.white {
    & #{$icons} {
      fill: $color-white;
    }
  }
}
