@import "../../css/App.scss";

.errors {
  background-color: $color-white;
  border-radius: 0.3125rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.625rem;
  margin-bottom: 0.625rem;
  position: relative;
  min-height: 3.125rem;
  display: flex;
  box-shadow: $box-shadow;
  flex-wrap: wrap;
  &:before {
    position: absolute;
    background-color: $color-red;
    width: 0.25rem;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0.3125rem;
  }

  h4 {
    font-size: 1rem;
    font-family: $font-family-bold;
    font-weight: $font-weight-bold;
    margin: auto 0 auto 0.3125rem;
  }

  span {
    font-size: 1rem;
    margin: auto 0;
  }
}
