@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap");

//Colours
$color-white: #ffffff;
$color-blue: #007dbd;
$color-dark-blue: #016ca1;
$color-green: #75bd48;
$color-black: #333;
$color-grey-1: #fafafa;
$color-grey-2: #cccccc;
$color-grey-3: #eeeeee;
$color-grey-4: #666666;
$color-grey-5: #999999;
$color-grey-6: #d8d8d8;
$color-orange: #ee5200;
$color-yellow: #ffd700;
$color-pink: #e84176;
$color-red: red;

$color-fog: $color-orange;
$color-uco: $color-yellow;
$color-mixed: $color-pink;
$color-text: $color-grey-4;
$color-text-focus: $color-black;
$color-field: $color-grey-1;
$color-field-border: $color-grey-2;
$field-border-radius: 0.3125rem;
$field-append-background: $color-grey-3;
$menu-item-unselected: $color-grey-5;
$border-color: $color-grey-6;
$color-box-shadow: $color-grey-4;

//Font
$font-family: "Karla", sans-serif;
$font-family-bold: "Karla bold", sans-serif;
$font-size: 16px;
$font-weight: normal;
$font-weight-bold: 700;

$box-shadow: 0.125rem 0.125rem 0.3125rem 0 $color-box-shadow;

$screen-xs: 768;
$screen-sm: 992;
$screen-md: 1200;
$screen-lg: 1500;
$screen-xl: 1800;

:export {
  fogColor: $color-fog;
  ucoColor: $color-uco;
  mixedColor: $color-mixed;
  fieldBackgroundColor: $color-field;
  fieldBorderColor: $color-field-border;
  screenXs: $screen-xs;
  screenSm: $screen-sm;
  screenMd: $screen-md;
  screenLg: $screen-lg;
  screenXl: $screen-xl;
}
