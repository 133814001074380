@import "~bootstrap/scss/bootstrap";
@import "./Variables.module.scss";

.btn {
  line-height: 2.6875rem;
  &.btn-success {
    background-color: $color-green;
    color: $color-white;
  }

  &.btn-primary {
    background-color: $color-blue;
    color: $color-white;
    border: none;

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled) {
      background-color: $color-dark-blue;
      box-shadow: 0 0 0 0.2rem $color-blue;
    }
  }
}

.navbar {
  &-light {
    .navbar-brand {
      color: $color-grey-4;
      font-weight: 700;
      font-family: $font-family-bold;
    }
  }
}

.input-group {
  &:focus-within {
    -webkit-box-shadow: 0 0 0 1px $color-text-focus inset;
    box-shadow: 0 0 0 1px $color-text-focus;
    border-radius: $field-border-radius;
  }

  .form-control {
    border-top: 1px solid $color-field-border;
    border-bottom: 1px solid $color-field-border;
    border-left: 1px solid $color-field-border;
    border-right: 1px solid $color-field-border;
    & + .input-group-append {
      & > .input-group-text {
        border-top: 1px solid $color-field-border;
        border-bottom: 1px solid $color-field-border;
        border-right: 1px solid $color-field-border;
        border-left: 1px solid $color-field-border;
        background-color: $field-append-background;
      }
    }

    &:focus {
      box-shadow: none;
      color: $color-text-focus;
      border: none;

      & + .input-group-append {
        & > .input-group-text {
          border: none;

          & svg {
            width: 1.2rem;
          }
        }
      }
    }
  }

  &-text {
    background-color: $color-field;
    border: none;
  }
}

.form-control {
  height: calc(1.8em + 1rem + 5px);
  color: $color-text;
  background-color: $color-field;
  border-radius: $field-border-radius;
  border-color: $color-field-border;

  &:focus {
    background-color: $color-field;
    color: $color-text-focus;
    border-color: $color-text-focus;
    box-shadow: none;
  }
}

.form-check {
  $formCheck: #{&};
  &-lg {
    #{$formCheck}-input {
      line-height: 3;
      width: 30px;
      height: 30px;
      margin-left: -1.25rem;
      margin-top: 0;
    }

    #{$formCheck}-label {
      line-height: 2;
      height: 2rem;
      margin-left: 1.5rem;
    }
  }
}
