@import "../../css/App.scss";

.Login {
  .logo {
    text-align: center;
    padding-top: calc(10%);
    img {
      width: calc(30%);
    }
  }

  .login-image {
    position: relative;
    height: 100vh;
    background-color: $color-blue;
    background-image: url("../../assets/bg-logo-image.png");
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto;
  }

  .form--login {
    padding: 2.5rem 3.125rem;

    @include bp(bp-sm) {
      padding: 0.625rem 1.875rem;
    }

    @include bp(bp-xs) {
      padding: 3.125rem 1.875rem;
    }

    label {
      color: $color-white;
    }

    .input-group {
      &:focus-within {
        -webkit-box-shadow: 0 0 0 2px #75bd48 inset;
        box-shadow: 0 0 0 2px #75bd48;
        border-radius: 5px;
      }

      .form-control {
        border: none;
        background-color: $color-white;

        &:focus {
          box-shadow: none;
          color: $color-text-focus;

          & + .input-group-append {
            & > .input-group-text {
              border: none;
              & svg {
                width: 1.2rem;
              }
            }
          }
        }

        & + .input-group-append {
          & > .input-group-text {
            border: none;
            background-color: $color-white;
          }
        }
      }

      &-text {
        background-color: $color-white;
        border: none;
      }
    }
  }

  .fog-image {
    height: 100vh;
    background-image: url("../../assets/login-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .forgot-password {
    margin: 1.875rem 0;
    text-align: center;
  }

  .login {
    font-size: 1rem;
    line-height: 2rem;
  }
}
