@import "../../css/App.scss";

.CreateAccount {
  form {
    width: 100%;
    padding: 2rem 4rem;

    @include bp(bp-xs) {
      padding: 2rem 1rem;
    }
  }
}
